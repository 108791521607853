import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Patrocinador = () => (
  <Layout>
    <SEO
      title="Patrocinador"
      description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
    />
    <Container>
      <Common />
      <p>
        Gracias por tu interés en sumarte a nuestra causa, cientos de familias
        del estado de México requieren que empresas como la tuya se unan a su
        batalla, seguramente ustedes cuántas con herramientas que les sirven a
        ellos para luchar fuertes cada dia.
      </p>
      <p>
        A continuación te pediremos alguna información que nos ayudará a
        agilizar el proceso
      </p>
      <Iframe
        src="https://amvi-mx.typeform.com/to/DIPoIt"
        title="Patrocinador"
      />
    </Container>
  </Layout>
);

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 450px;
`;

export default Patrocinador;
